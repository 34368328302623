<template>
    <div class="supplier-add" v-if="fetched">
        <div class="vx-row mb-base">
            <div class="vx-col lg:w-1/2 w-full">
                <vx-card title="Informationen" class="h-full">
                    <div class="vx-row">
                        <div class="vx-col lg:w-full w-full">
                            <div class="vx-row">
                                <div class="vx-col lg:w-1/3">
                                    <p>Typ:
                                        <mg-select :options="typeOptions" v-model="supplierData.type" @select="onSelectCustomerType"
                                                   @remove="supplierData.type=null"
                                                   placeholder="Type auswählen" track-by="value" label="label"
                                        >
                                        </mg-select>
                                    </p>
                                </div>
                            </div>
                            <div class="vx-row">
                                <div class="vx-col lg:w-full">
                                    <vs-input class="w-full" label="Kundennummer *" data-vv-as="Kundennummer" name="customer_number" v-model="supplierData.customer_number" type="text" v-validate="'required'"/>
                                    <span class="text-danger text-sm" v-if="errors.has('customer_number')">{{ errors.first('customer_number') }}</span>
                                </div>
                            </div>
                            <div class="vx-row mt-4">
                                <div class="vx-col lg:w-full">
                                    <vs-input class="w-full" label="Firma *" data-vv-as="Firma" name="company_name" v-model="supplierData.company_name" type="text" v-validate="'required'"/>
                                    <span class="text-danger text-sm" v-if="errors.has('company_name')">{{ errors.first('company_name') }}</span>
                                </div>
                            </div>
                            <div class="vx-row mt-4">
                                <div class="vx-col lg:w-full">
                                    <vs-input class="w-full" label="Anschrift 1 *" data-vv-as="Anschrift 1" name="address_line_1" v-model="supplierData.address_line_1" type="text" v-validate="'required'"/>
                                    <span class="text-danger text-sm" v-if="errors.has('address_line_1')">{{ errors.first('address_line_1') }}</span>
                                </div>
                            </div>
                            <div class="vx-row mt-4">
                                <div class="vx-col lg:w-full">
                                    <vs-input class="w-full" label="Anschrift 2" data-vv-as="Anschrift 2" name="address_line_2" v-model="supplierData.address_line_2" type="text" />
                                </div>
                            </div>
                            <div class="vx-row mt-4">
                                <div class="vx-col lg:w-1/3">
                                    <vs-input class="w-full" label="Postleitzahl *" data-vv-as="Postleitzahl" name="zip_code" v-model="supplierData.zip_code" type="text" v-validate="'required'" />
                                    <span class="text-danger text-sm" v-if="errors.has('zip_code')">{{ errors.first('zip_code') }}</span>
                                </div>

                                <div class="vx-col lg:w-2/3">
                                    <vs-input class="w-full" label="Standort *" data-vv-as="Standort" name="city" v-model="supplierData.city" type="text" v-validate="'required'" />
                                    <span class="text-danger text-sm" v-if="errors.has('city')">{{ errors.first('city') }}</span>
                                </div>
                            </div>

                            <div class="vx-row mt-4">
                                <div class="vx-col lg:w-1/3">
                                    <vs-select label="Land" class="w-full" v-model="supplierData.country">
                                        <vs-select-item :key="index" :value="item.value" :text="item.label"
                                                        v-for="item, index in countries"/>
                                    </vs-select>
                                </div>
                            </div>
                            <div class="vx-row mt-4">
                                <div class="vx-col lg:w-1/2">
                                    <vs-input class="w-full" label="Telefon" data-vv-as="Telefon" name="phone" v-model="supplierData.phone" type="text" />
                                </div>

                                <div class="vx-col lg:w-1/2">
                                    <vs-input class="w-full" label="Fax" data-vv-as="Fax" name="fax" v-model="supplierData.fax" type="text" />
                                </div>
                            </div>
                            <div class="vx-row mt-4">
                                <div class="vx-col lg:w-full">
                                    <vs-input class="w-full" label="E-Mail-Adresse *" data-vv-as="E-Mail-Adresse" name="email" v-model="supplierData.email" type="email" v-validate="'required'"/>
                                    <span class="text-danger text-sm" v-if="errors.has('email')">{{ errors.first('email') }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </vx-card>
            </div>
            <div class="vx-col lg:w-1/2 w-full">
                <vx-card title="Weiter Details" class="h-full">
                    <div class="vx-row">
                        <div class="vx-col lg:w-full w-full">
                            <div class="vx-row">
                                <div class="vx-col lg:w-full">
                                    <vs-input class="w-full" label="Webseite" data-vv-as="Webseite" name="website" v-model="supplierData.websites" type="text" v-validate="'required'"/>
                                </div>
                            </div>
                            <div class="vx-row mt-4">
                                <div class="vx-col lg:w-full">
                                    <vs-input class="w-full" label="Ust. Id Nr." data-vv-as="Ust. Id Nr." name="ust_id" v-model="supplierData.ust_id" type="text" v-validate="'required'"/>
                                </div>
                            </div>
                            <div class="vx-row mt-4">
                                <div class="vx-col lg:w-full">
                                    <vs-input class="w-full" label="Payment Deadline" data-vv-as="Payment Deadline" name="payment_deadline" v-model="supplierData.payment_deadline" type="text" />
                                </div>
                            </div>
                            <div class="vx-row mt-4">
                                <div class="vx-col lg:w-full">
                                    <vs-input class="w-full" label="IBAN" data-vv-as="IBAN" name="bank_iban" v-model="supplierData.bank_iban" type="text" />
                                </div>
                            </div>
                            <div class="vx-row mt-4">
                                <div class="vx-col lg:w-full">
                                    <vs-input class="w-full" label="SWIFT/BIC" data-vv-as="SWIFT/BIC" name="bank_bic" v-model="supplierData.bank_bic" type="text" />
                                </div>
                            </div>
                            <div class="vx-row mt-4">
                                <div class="vx-col lg:w-full">
                                    <vs-input class="w-full" label="Kontoinhaber" data-vv-as="Kontoinhaber" name="bank_account_owner" v-model="supplierData.bank_account_owner" type="text" />
                                </div>
                            </div>

                            <div class="vx-col lg:w-full mt-5">
                                <h3>Interne Notiz</h3>
                                <label for="comment" class="vs-input--label">nur für Interne Zwecke</label>
                                <vs-textarea class="w-full"
                                             data-vv-as="nur für Interne Zwecke"
                                             name="comment"
                                             id="comment"
                                             height="200px"
                                             v-model="supplierData.internal_notice">
                                </vs-textarea>
                            </div>

                        </div>
                    </div>
                </vx-card>
            </div>
        </div>

        <div class="flex mt-8 justify-end">
            <vs-button color="warning" type="border" class="mb-4" :to="{name :'suppliers'}">Abbrechen</vs-button>
            <vs-button class="ml-3 mb-4" @click="saveClient">Hinzufügen</vs-button>
        </div>
    </div>
</template>

<script>
    import ApiService from "../../../api";
    import MgSelect from "@/components/mg-select/MgSelect";
    import staticOptions from "@/mixins/static/options";
    import {mapGetters} from 'vuex';

    export default {
        components: {  MgSelect},
        data() {
            return {
                fetched: false,
                nextCustomerNumbers: [],
                countries: [],
                supplierData: {
                    workspace_id: null,
                    business_client: 0,
                    type: "partner",
                    customer_number: null,
                    company_name: null,
                    address_line_1: null,
                    address_line_2: null,
                    fast_registration: 0,
                    zip_code: null,
                    city: null,
                    country: 1,
                    phone: null,
                    fax: null,
                    websites: null,
                    ust_id: null,
                    payment_deadline: 14,
                    internal_comment: null,
                    bank_iban: null,
                    bank_bic: null,
                    bank_account_owner: null,
                    email: null,
                    internal_notice:null
                }
            }
        },
        mounted() {

            this.fetchInitialData();
            this.fetchCountries();
        },
      created() {
        this.supplierData.workspace_id = this.workspace.id
      },
      computed: {
          ...mapGetters([
            'workspace'
          ]),
            typeOptions() {
                return staticOptions.clientTypes;
            },
        },
        methods: {
          onSelectCustomerType(type){
            this.getNextFreeCustomerNumber(type.value)
          },
          getNextFreeCustomerNumber(type){
            this.supplierData.customer_number = this.nextCustomerNumbers[type]
          },
          fetchInitialData() {
            ApiService.get('clients/getNextCustomerNumberByTypes').then((response) => {
              if(response.data.result) {
                this.nextCustomerNumbers =  response.data.result;
                this.supplierData.customer_number =  response.data.result[this.supplierData.type];
              }
              this.fetched = true;
            })
          },
            fetchCountries() {
                this.countries = staticOptions.countries;
            },

            saveClient() {
                ApiService.post('clients', this.supplierData).then((response) => {
                    if(response.data.result) {
                        this.$router.push({ name: 'suppliers-detail', params: { id: response.data.result.id }})
                        return this.$vs.notify({
                            title: 'Erfolgreich',
                            text: 'Der Lieferant wurde erfolgreich erstellt',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'success'
                        })
                    }

                    this.$vs.notify({
                        title: 'Fehlgeschlagen',
                        text: 'Der Lieferant konnte nicht erstellt werden.',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                }).catch((error) => {
                    this.$vs.notify({
                        title: 'Fehlgeschlagen',
                        text: 'Der Lieferant konnte nicht erstellt werden.',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                });
            }
        },
    }
</script>
